import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import wallet_qr from "/assets/img/wallet/wallet_qr.jpg";
import Input from "/components/core/labels/Input";

import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomButton from "/components/core/ButtonDefault";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import Select from "/components/core/labels/Select";
import useSWRImmutable from "swr/immutable";
import { useDepositAddress } from "/api/cashier/direct-deposit/deposit-address.js";
import { useAvaliableChains } from "/api/cashier/direct-deposit/available-chains.js";
import { generateSVGString } from '@intosoft/qrcode';

const Title = ({ id }) => {
  const { t } = useTranslation();
  return (
    <p className="df gap-6">
      <span>0{id}</span> {t(`wallet.deposit.steps.${id}`)}
    </p>
  );
};

/**
 * @type {import("/api/cashier/direct-deposit/available-chains.js").AvailableChain[]}
 */
const defaultChains = [
  {
    chainId: 1,
    chainName: "Test Avalanche 1",
    chainIcon: null,
    requiredConfirmations: 12,
    addressType: null,
  },
  {
    chainId: 2,
    chainName: "Test Avalanche 2",
    chainIcon: null,
    requiredConfirmations: 12,
    addressType: null,
  },
  {
    chainId: 3,
    chainName: "Test Avalanche 3",
    chainIcon: null,
    requiredConfirmations: 12,
    addressType: null,
  },
];

/**
 * @param {{ kycStatus }} _props
 * @returns 
 */
export default function Flow(_props) {
  const { t } = useTranslation();
  const [selectedChain, setSelectedChain] = useState("");
  const axiosClient = useAxiosWithAuth();
  const availableChains = useAvaliableChains(axiosClient, {
    fallbackData: [], // todo: adjust to the real fallbackData
    revalidateOnMount: true, // to revalidate fallbackData
  });

  const chainList = useMemo(
    () =>
      (availableChains.data || [])
        .filter(({ chainId }) => chainId !== undefined && chainId !== null)
        .reduce((acc, { chainId, chainName }) => {
          acc[chainId] = chainName;
          return acc;
        }, {}),
    [availableChains.data]
  );

  /**
   * This address should be gained only after the user has selected the chain manually
   * It make sense to use trusted event to trigger this request, but for now it's fine
   * @type {import("swr").SWRResponse<{ address: string, memo: string|null, tag: string|null }, { 'message': 'User has not completed KYC', 'code': 'full_kyc_required' }>}
   */
  const depositAddress = useDepositAddress(axiosClient, selectedChain);

  const selectChainID = (e) => {
    setSelectedChain(e.target.value);
  };

  return (
    <>
      <p className="fixel_22 text-center">
        {t("wallet.deposit.instructions")}
      </p>
      <div className="list-rows df fd-c fixel_16_md">
        <Title id={1} />
        <Title id={2} />
        <Title id={3} />
        {(() => {
          if (availableChains.error) {
            return (
              <div>
                <p>{availableChains.error.message}</p>
                <CustomButton
                  onClick={() => availableChains.mutate()}
                  classname={`go-back grey as-c`}
                  def={40}
                  title={t("labels.retry")}
                />
              </div>
            );
          }
          if (!availableChains.data) {
            return (
              <Select
                handleChange={selectChainID}
                list={chainList}
                def={t("wallet.deposit.selectChain")}
                value={selectedChain}
              />
            );
          }
          return (
            <Select
              handleChange={selectChainID}
              list={chainList}
              def={t("wallet.deposit.selectChain")}
              value={selectedChain}
            />
          );
        })()}
        <Title id={4} />

        {(() => {
          if (depositAddress.error) {
            return (
              <AddressErrorView
                data={{
                  address: depositAddress.error.message,
                  qr: "",
                }}
              />
            );
          }
          if (depositAddress.isLoading && !depositAddress.data) {
            return (
              <AddressView
                data={{
                  address: t("labels.loading"),
                  qr: t("labels.loading"),
                }}
              />
            );
          }
          if (depositAddress.isValidating) {
            return (
              <AddressView
                data={{
                  address: t("labels.loading"),
                  qr: t("labels.loading"),
                }}
              />
            );
          }
          return <AddressView data={depositAddress.data} />;
        })()}

        <Title id={5} />
        <Title id={6} />
      </div>
    </>
  );
}

function CopyAddress({ text }) {
  const [copy, setCopy] = useState(false);
  const onCopy = () => {
    sto && clearTimeout(sto);
    setCopy(true);
    sto = setTimeout(() => setCopy(false), 1500);
  };
  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <div className={"icon_copy icon-24 ar-1" + (copy ? " copyed" : "")}></div>
    </CopyToClipboard>
  );
}

function AddressView({ data }) {
  return (
    <>
      <div className="wallet-ddress df aic-">
        <Input
          readOnly={true}
          value={data?.address || "Ox"}
          name="walletAddress"
        />
        <CopyAddress text={data?.address} />
      </div>

      <div className="qr as-c df cover ar-1">
        {data?.address && data?.address !== t("labels.loading") && <RenderQR address={data?.address} />}
      </div>
    </>
  );
}

export const RenderQR = ({ address }) => {
  const svgString = generateSVGString({
    length: 200,
    padding: 0,
    errorCorrectionLevel: "H",
    "shapes": {
        "eyeFrame": "rounded" ,
        "eyeball": "styleC" ,
        "body": "star-small" 
    },
    "colors": {
        "background": "transparent",
        "body": "rgb(1, 141, 160)",
        "eyeFrame": {
            "topLeft": "rgb(239, 97, 97)",
            "topRight": "rgb(239, 97, 97)",
            "bottomLeft": "rgb(239, 97, 97)"
        },
        "eyeball": {
            "topLeft": "rgb(75, 75, 180)",
            "topRight": "rgb(75, 75, 180)",
            "bottomLeft": "rgb(75, 75, 180)"
        }
    },
     value: address,
  });
  return <div dangerouslySetInnerHTML={{ __html: svgString }} />;
};

function AddressErrorView({ data }) {
  return (
    <>
      <div className="wallet-ddress df aic-">
        <Input
          readOnly={true}
          value={data?.address || "Ox"}
          name="walletAddress"
        />
        <CopyAddress text={data?.address} />
      </div>

      <div className="qr as-c df cover ar-1">
        <img src={data?.qr || ""} alt="" />
      </div>
    </>
  );
}
