import React from "react";
import DelayLink from "/components/core/DelayLink";
import FunticoLogo from "/components/core/Logo";
import Socials from "/components/core/Socials";
import Steam from "/assets/img/steam.svg";
import { useTranslation } from "react-i18next";

const gen = [
  { to: "/", title: "layout.menus.home" },
  { to: "/tournaments", title: "layout.menus.tournaments", need_auth: true },
  { to: "/backpack", title: "layout.menus.backpack", need_auth: true },
  { to: "/marketplace", title: "layout.menus.marketplace" },
  { to: "/loot-packs", title: "layout.menus.lootPacks", need_auth: true },
  { to: "/wheel-of-fortune", title: "layout.menus.wheel", need_auth: true },
];
const sec = [
  {
    to: "https://about.funtico.com/",
    title: "layout.menus.aboutUs",
    target: "_blank",
  },
  {
    to: "https://summoners.funtico.com/",
    title: "layout.menus.summonersNft",
    target: "_blank",
  },
  // {
  //   to: "https://zealy.io/cw/funticocom/questboard",
  //   title: "Join Whitelist",
  //   target: "_blank",
  // },
  {
    to: "https://litepaper.funtico.com/funtico-litepaper",
    title: "layout.menus.checkLitepaper",
    target: "_blank",
  },
  {
    to: "https://www.youtube.com/channel/UCKNSppHiWRtKlqxyvAntNwQ",
    title: "layout.menus.checkTrailers",
    target: "_blank",
  },
  {
    to: "https://store.steampowered.com/app/2774710/Heroes_of_the_Citadel/",
    title: "layout.menus.wishlist",
    icon: () => <Steam />,
    target: "_blank",
  },
];
const pr = [
  {
    to: "mailto:support@funtico.com",
    title: "layout.menus.contact",
    target: "_blank",
  },
  { to: "/terms-and-conditions", title: "layout.menus.terms" },
  { to: "/privacy-policy", title: "layout.menus.privacy" },
];

export default function Footer() {
  const { t } = useTranslation();
  const date = new Date().getFullYear();

  return (
    <>
      <footer className="footer">
        <div className="section_content df fd-c pd-lr-md">
          <div className="footer_top df">
            <div className="footer_left col">
              <FunticoLogo />

              <p className="subtitle mr-t-16 op">
                {t("layout.menus.subtitle")}
              </p>
            </div>
            <div className="footer_center col df">
              <div className="pages df ai-st fd-c gen">
                {gen.map((l) => (
                  <DelayLink key={l.to} {...l} className="l fixel_16_sbl op">
                    {t(l.title)}
                  </DelayLink>
                ))}
              </div>
              <div className="pages df ai-st fd-c sec">
                {sec.map(({ icon, ...l }, i) => (
                  <DelayLink {...l} className="l fixel_16_sbl op" key={i}>
                    {icon && <div className="icon-24">{icon()}</div>}
                    {t(l.title)}
                  </DelayLink>
                ))}
              </div>
            </div>
            <div className="footer_right col df ai-st jc-sb">
              <div className="pages df ai-st fd-c pr">
                {pr.map((l) => (
                  <DelayLink key={l.to} {...l} className="l fixel_16_sbl op">
                    {t(l.title)}
                  </DelayLink>
                ))}
              </div>
              <Socials />
            </div>
          </div>
          <div className="footer_bottom df ai-c jc-sb">
            <p className="fixel_16_md ">
              {t("layout.menus.founded", { date })}
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
