import React, { useContext, useEffect } from "react";

import Password from "/components/core/labels/Password";
import CustomButton from "/components/core/ButtonDefault";
import useForm from "/utils/hooks/useForm";
import FormLabels from "/components/core/labels/Form";
import AuthContext from "/context/AuthProvider";
import { useTranslation } from "react-i18next";

export function ResetPassword({ state }) {
  const { t } = useTranslation();
  const useform = useForm({}, "auth/forgot");
  const { isSending, formResponse, handleSubmit } = useform;
  const { auth } = useContext(AuthContext);

  const labels = [
    {
      placeholder: t("registration.labels.email.placeholder"),
      type: "email",
      name: "email",
    },
  ];

  useEffect(() => {
    if (formResponse?.data?.expires && !formResponse?.errors) {
      state.setPopUp("confirm", {
        title: t("messages.passResetSuccess"),
        subtitle: t("messages.passResetSuccessDesc"),
        close: t("labels.okay"),
      });
    }
  }, [formResponse]);

  return (
    <>
      <div className="title">
        <p className="t fixel_36">{t("registration.forgotPassword")}</p>
        <p className="s">{t("registration.resetPassword")}</p>
      </div>

      <div className="content_data df fd-c">
        <form className="form df fd-c" onSubmit={handleSubmit}>
          <div className="form_content">
            <FormLabels {...useform} labels={labels} />
          </div>
          <CustomButton
            type="submit"
            classname="submit pr as-c"
            def={40}
            title={t("labels.confirm")}
            disabled={isSending}
          />
        </form>
      </div>
      {!auth?.token && (
        <div className="switch_form jc-c fw as-c df">
          <p className="fixel_16_sbl">{t("registration.resetNoAccess")}</p>
          <button className="def wt" onClick={(e) => state.setPopUp("reg")}>
            {t("registration.resetCreateNewAccount")}
          </button>
        </div>
      )}
    </>
  );
}

export function CreatePassword({ state }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="title">
        <p className="t fixel_36">{t("registration.resetCreateNewPass")}</p>
        <p className="s">{t("registration.resetCreateNewPassSuccess")}</p>
      </div>

      <div className="content_data df fd-c">
        <form className="form df fd-c">
          <div className="form_content">
            <Password placeholder={t("labels.enterPassword")} />
            <Password placeholder={t("labels.confirmPassword")} />
          </div>
          <button className="def submit type-40 pr as-c br-16">
            {t("labels.confirm")}
          </button>
        </form>
      </div>
      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">{t("registration.resetChatWithUs")}</p>
        <button className="def wt">{t("registration.resetDiscord")}</button>
      </div>
    </>
  );
}
