import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Flow from "./components/Withdraw-Flow";
import Confirm from "./components/Withdraw-Confirm";
import { payToLocaleString } from "/utils/utils";
import CustomButton from "/components/core/ButtonDefault";

const parts = {
  confirm: Confirm,
  flow: Flow,
};

export default function Withdraw(props) {
  const { t } = useTranslation();
  const { onClose, state, auth } = props;
  const [activePart, setActivePart] = useState({
    path: "flow",
    queryParams: {},
  });
  const Component = parts[activePart.path];

  const changePart = (path = "", queryParams = {}) => {
    setActivePart({ path, queryParams });
  };

  const onConfirm = () => {
    onClose();

    state.setPopUp("data", {
      custom: (e) => (
        <div className="withdraw-confirm df fd-c ai-c text-center gap-32">
          <p className="fixel_26">{t("wallet.withdraw.successful")}</p>
          <div className="df fd-c ai-c gap-16">
            <p className="fixel_16_sbl">
              {t("wallet.withdraw.confirmRequest")}
            </p>
            <div className="df ai-c gap-6">
              <div className="tico_icon"></div>
              <p className="fixel_26">
                {payToLocaleString(activePart.queryParams.amountValue)}
              </p>
            </div>
            <p className="fixel_16_sbl">
              {t("wallet.withdraw.checkEmail", {
                email: auth?.email || "youremail@funtico.com",
              })}
            </p>
          </div>
          <CustomButton
            onClick={() => state.setPopUp("")}
            classname={`grey`}
            def={40}
            title={t("labels.close")}
          />
        </div>
      ),
    });
  };

  return (
    <>
      <Component
        {...{ ...props, changePart, onConfirm, data: activePart.queryParams }}
      />
    </>
  );
}
