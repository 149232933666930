import React, { useEffect } from "react";

import useForm from "/utils/hooks/useForm";
import FormLabels from "/components/core/labels/Form";
import CustomButton from "/components/core/ButtonDefault";
import { useTranslation } from "react-i18next";

export default function Write({ state }) {
  const useform = useForm({}, "user/contact");
  const { isSuccess, isSending, handleSubmit } = useform;
  const { t } = useTranslation();

  const labels = [
    {
      placeholder: t("labels.enterSubject"),
      name: "subject",
    },
    {
      placeholder: t("labels.yourMessage"),
      type: "textarea",
      name: "message",
    },
  ];

  useEffect(() => {
    isSuccess &&
      state.setPopUp("success", {
        title: t("message.messageSent"),
        subtitle: t("message.messageSentDesc"),
        close: t("labels.okay"),
      });
  }, [isSuccess]);

  return (
    <>
      <div className="title">
        <p className="t fixel_36">{t("profile.kycPopupCta")}</p>
      </div>

      <div className="content_data df fd-c">
        <form onSubmit={handleSubmit} className="form df fd-c">
          <div className="form_content">
            <FormLabels {...useform} labels={labels} />
          </div>

          <CustomButton
            classname="submit pr as-c"
            def={40}
            type="submit"
            title={t("labels.sendRequest")}
            disabled={isSending}
          />
        </form>
      </div>
      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">{t("registration.orChatWithUs")}</p>
        <button className="def wt" type="button">
          {t("registration.discordCommunity")}
        </button>
      </div>
    </>
  );
}
