import React from "react";
import RegistrationLabels from "/components/pop-ups/RegistrationLabels";
import { useTranslation } from "react-i18next";

export default function Registration({ state }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="title">
        <p className="t fixel_36">{t("message.welcomeToFuntico")}</p>
      </div>

      <div className="content_data df fd-c">
        {/* <EnterViaSocials state={state} />
        <div className="or df ai-c fixel_16_sbl">OR</div> */}
        <RegistrationLabels state={state} />
      </div>
      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">{t("registration.alreadyHaveAccount")}</p>
        <button className="def wt" onClick={(e) => state.setPopUp("login")}>
          {t("registration.signInNow")}
        </button>
      </div>
    </>
  );
}
