/**
 * @typedef {{ balance: string, withdrawableBalance: string, blockedBalance: string }} WithdrawableBalance
 */

import React from "react";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {{ isLoading, data: WithdrawableBalance, error }} param0
 * @returns
 */
export function Balance({ isLoading, data, error }) {
  const { t } = useTranslation();
  if (isLoading && !data) {
    return (
      <BalanceLayout
        data={{
          balance: t("labels.loading"),
          withdrawableBalance: t("labels.loading"),
          blockedBalance: t("labels.loading"),
        }}
      />
    );
  }
  if (error) {
    // handle error
    return (
      <BalanceLayout
        data={{
          balance: "?",
          withdrawableBalance: "?",
          blockedBalance: "?",
        }}
      />
    );
  }
  return <BalanceLayout data={data} />;
}

/**
 *
 * @param {{ data: WithdrawableBalance }} param0
 * @returns
 */
function BalanceLayout({ data }) {
  const { t } = useTranslation();
  return (
    <div className="tico-information df fd-c gap-16">
      <div className="row df jc-sb ai-c gap-10">
        <p className="fixel_14 color-neu">{t("wallet.balance.current")}</p>
        <div className="df ai-c gap-6">
          <p className="fixel_16_sbl">{data.balance}</p>
        </div>
      </div>
      <div className="row df jc-sb ai-c gap-10">
        <p className="fixel_14 color-neu">{t("wallet.balance.locked")}</p>
        <div className="df ai-c gap-6">
          <p className="fixel_16_sbl">{data.blockedBalance}</p>
        </div>
      </div>

      <div className="full-line"></div>

      <div className="row df jc-sb ai-c gap-10 fixel_22">
        <p>{t("wallet.balance.availableToWithdraw")}</p>
        <div className="df ai-c gap-6">
          <div className="tico_icon"></div>
          <p>{data.withdrawableBalance}</p>
        </div>
      </div>
    </div>
  );
}
