import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import wallet_qr from "/assets/img/wallet/wallet_qr.jpg";
import Input from "/components/core/labels/Input";

import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomButton from "/components/core/ButtonDefault";

const walletAddress = "0x2bE13B09Ed2083B88272346b12Ed5A3c33482c1a";
let sto = null;

const Title = ({ id }) => {
  const { t } = useTranslation();
  return (
    <p className="df gap-6">
      <span>0{id}</span> {t(`wallet.buySection.exchange.steps.${id}`)}
    </p>
  );
};

export default function Exchange({ back, cryptoExs }) {
  const { t } = useTranslation();
  const [copy, setCopy] = useState(false);

  const onCopy = () => {
    sto && clearTimeout(sto);
    setCopy(true);
    sto = setTimeout(() => setCopy(false), 1500);
  };

  return (
    <>
      <p className="fixel_22 text-center">
        {t("wallet.buySection.exchange.instructions")}
      </p>
      <div className="list-rows df fd-c fixel_16_sbl">
        <Title id={1} />
        <div className="df icons">
          {cryptoExs.map((ex) => (
            <div className={"icon df icon_" + ex.icon} key={ex.icon} />
          ))}
        </div>
        <Title id={2} />
        <Title id={3} />
        <Title id={4} />
        <Title id={5} />
        <Title id={6} />

        <div className="wallet-ddress df aic-">
          <Input readOnly={true} value={walletAddress} name="walletAddress" />
          <CopyToClipboard text={walletAddress} onCopy={onCopy}>
            <div
              className={"icon_copy icon-24 ar-1" + (copy ? " copyed" : "")}
            ></div>
          </CopyToClipboard>
        </div>

        <div className="qr as-c df cover ar-1">
          <img src={wallet_qr} alt={t("wallet.buySection.exchange.qrCode")} />
        </div>

        <Title id={7} />

        <CustomButton
          onClick={back}
          classname={`go-back grey as-c`}
          def={40}
          title={t("labels.back")}
        />
      </div>
    </>
  );
}
