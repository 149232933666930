import React from "react";
import { useTranslation } from "react-i18next";
import { TicoAmount } from "./TicoAmount";
import { Rate } from "./Rate";

/**
 *
 * @param {{ amount: React.ReactNode, rate: import("./Rate").Props  }} param0
 * @returns
 */
export function Layout({ amount, rate, ...props }) {
  const { t } = useTranslation();

  return (
    <div className="row-receive df jc-sb" {...props}>
      <p className="fixel_16_md">{t("wallet.buySection.fiat.toReceive")}</p>
      {/*todo: show if it's isValidating */}
      <div className="df fd-c gap-6">
        <div className="df ai-c gap-6">
          <TicoAmount>{amount}</TicoAmount>
        </div>
        <div className="fixel_14 color-neu">
          <Rate {...rate} />
        </div>
      </div>
    </div>
  );
}
